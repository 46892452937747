<template>
	<!-- 加班记录 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">休假记录</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd || showUpload || showCheck || showDownload">
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
						v-if="showAdd">添加</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="rowExcelUpload()"
						v-if="showUpload">导入</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="checkedHandle()"
						v-if="showCheck">审核</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="downloadTemplate()"
						v-if="showDownload">模板下载</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="状态" class="selFormItem">
							<el-select class="selSearchInput" v-model="checkState" placeholder="请选择">
								<el-option label="未审核" value="0"></el-option>
								<el-option label="已审核" value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="休假日期" class="selFormItem" style="margin-top:2px">
							<el-date-picker class="selDateInput" v-model="leaveTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="selGetDataList()">检索</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 545px;" body-style="height:510px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="490" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
								header-cell-class-name="stepPointTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column label="NO." width="80" align="center" fixed="left">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="workMan" label="人员" align="center">
								</el-table-column>
								<el-table-column prop="vacationDate" label="休假日期" align="center">
								</el-table-column>
								<el-table-column prop="vacationDays" label="天数" align="center">
								</el-table-column>
								<el-table-column prop="checkStateName" label="审核标识" align="center">
								</el-table-column>
								<el-table-column prop="sponsor" label="操作" align="center" v-if="showEdit || showDelete">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.id)"
											v-if="showEdit && scope.row.checkState == 0">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.id)"
											v-if="showDelete && scope.row.checkState == 0">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<checked-leave v-if="checkedLeaveVisible" ref="checkedLeave" @refreshDataList="getDataList"></checked-leave>

		<input class="input-file" type="file" @change="exportData" style="display: none"
			accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
	</div>
</template>

<script>
import AddOrUpdate from './vacation-add-or-update'
import CheckedLeave from './vacation-checked'
import $common from "@/utils/common.js"
import xlsx from 'xlsx'
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showUpload: false,
			showDownload: false,
			showCheck: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			form: {},
			addOrUpdateVisible: false,
			checkedLeaveVisible: false,
			workNo: $common.getItem("workNo"),
			subjectNo: $common.getItem("subjectNo"),
			checkState: "",
			leaveTime: [],
			dataList: [],
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			pageIndex2: 1
		};
	},
	components: {
		AddOrUpdate,
		CheckedLeave
	},
	mounted() {
		this.showSearch = $common.isAuth('vacation:search');
		this.showAdd = $common.isAuth('vacation:add');
		this.showUpload = $common.isAuth('vacation:upload');
		this.showDownload = $common.isAuth('vacation:download');
		this.showCheck = $common.isAuth('vacation:check');
		this.showEdit = $common.isAuth('vacation:edit');
		this.showDelete = $common.isAuth('vacation:delete');
		if (!this.showAdd && !this.showUpload && !this.showCheck && !this.showDownload) {
			this.pageSpan = 24;
		}
		this.pageIndex2 = 1
		this.getDataList();
	},
	methods: {
		//自适应高度
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		getRowClass(row, rowIndex) {
			if (!row.row.subList || row.row.subList.length === 0) {
				return 'row-expand-cover'
			}
			return ''
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/vacationinfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: this.subjectNo,
						checkState: this.checkState,
						beginTime: this.leaveTime[0] ? this.leaveTime[0] : "",
						endTime: this.leaveTime[1] ? this.leaveTime[1] : "",
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		rowExcelUpload() {
			document.querySelector('.input-file').click()
		},
		exportData() {
			if (!event.currentTarget.files.length) {
				return
			}
			// 拿取文件对象
			this.readExcel(event.currentTarget.files);
			document.querySelector('.input-file').value = '';
		},
		readExcel(files) {
			// console.log("begin readExcel")
			if (files.length <= 0) {
				return false;
			} else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
				this.$message.error('上传格式不正确，请上传xls或者xlsx格式');
				return false;
			}
			const fileReader = new FileReader();
			fileReader.onload = (ev) => {
				try {
					const data = ev.target.result;
					const workbook = xlsx.read(data, {
						type: 'binary',
						cellText: false,
						cellDates: true,
					});
					const wsname = workbook.SheetNames[0];//取第一张表
					const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname], { raw: false, dateNF: 'yyyy-MM-dd' });//生成json表格内容
					// console.log("ws",ws);
					let rowData = [...ws]
					this.excelRowVale = [];
					rowData.map(v => {
						let obj = {};
						obj.workNo = $common.getItem("workNo");
						obj.subjectNo = $common.getItem("subjectNo");
						obj.workMan = v.休假人;
						obj.vacationDate = v.休假时间;
						obj.vacationDays = v.休假天数;
						this.excelRowVale.push(obj);
					});
					// console.log("excelRowVale",this.excelRowVale);
					//重写数据
					// this.$refs.upload.value = '';
					this.saveExcelUpload();
				} catch (e) {
					// console.log(e)
					return false;
				}
			};
			fileReader.readAsBinaryString(files[0]);
			// console.log("end readExcel")
		},
		saveExcelUpload() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/vacationinfo/saveVacationInfoList",
				method: "post",
				data: this.$http.adornData({
					records: this.excelRowVale
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.getDataList()
						}
					});
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 新增节点
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		checkedHandle() {
			this.checkedLeaveVisible = true
			this.$nextTick(() => {
				this.$refs.checkedLeave.init()
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=3",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						window.open(data.body);
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		resetFormData() {
			this.checkState = "";
			this.meetTime = [];
		},
		deleteHandle(id) {
			this.$confirm(`确定要删除选择数据吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/vacationinfo/delete/" + id,
					method: 'post'
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped>
.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 110px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
